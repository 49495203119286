<template>
  <b-button variant="danger" @click.stop="onClick">
    <feather-icon icon="XIcon"></feather-icon>
    {{ $t("general.cancel") }}
  </b-button>
</template>

<script>
export default {
  name: `CancelButton`,
  methods: {
    onClick() {
      this.$emit(`onClick`);
    },
  },
};
</script>
