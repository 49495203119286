import Vue from "vue";

Vue.mixin({
  data() {
    return {
      vueNumericConfig: {
        minus: true,
        precision: 2,
      },
    };
  },

  methods: {
    updateCollection(collection, keyCol, newRecord) {
      let idx = collection.findIndex(x => x[keyCol] == newRecord[keyCol]);
      collection[idx] = newRecord;
      return collection;
    },
    updateCollectionByIdx(collection, idx, newRecord) {
      collection[idx] = newRecord;
      return collection;
    },
    removeFromCollection(collection, keyCol, keyValue) {
      let idx = collection.findIndex(x => x[keyCol] == keyValue);
      collection.splice(idx, 1);
    },
    clearObject(obj) {
      for (var p in obj) {
        if (typeof obj[p] === `number`) obj[p] = 0;
        else obj[p] = null;
      }
    },
    download(stream, filename) {
      var fileURL = window.URL.createObjectURL(new Blob([stream]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    copy(data) {
      this.$copyText(data);
      this.showToast(`copy`, data);
    },
    pascalCase(str) {
      return str.toString().replace(/\w+/g, function (w) {
        return w[0].toUpperCase() + w.slice(1).toLowerCase();
      });
    },
    getDistinctItems(array, key) {
      return Array.from(new Map(array.map(item => [item[key], item])).values());
    },
    shortenString(str) {
      if (str.length > 100) {
        return str.substring(0, 100) + "...";
      }
      return str;
    },
  },
});
