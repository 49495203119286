export default [
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/dashboard/Main.vue"),
    meta: {
      action: `read`,
      resource: `dashboard`,
    },
  },
  {
    path: "/client",
    name: "client-list",
    component: () => import("@/views/client/List.vue"),
    meta: {
      action: `read`,
      resource: `client`,
    },
  },
  {
    path: "/agent",
    name: "agent-list",
    component: () => import("@/views/agent/List.vue"),
    meta: {
      action: `read`,
      resource: `agent`,
    },
  },
  {
    path: "/users",
    name: "user-list",
    component: () => import("@/views/user/List.vue"),
    meta: {
      action: `read`,
      resource: `user`,
    },
  },
  {
    path: "/domain",
    name: "domain-list",
    component: () => import("@/views/domain/List.vue"),
    meta: {
      action: `read`,
      resource: `domain`,
    },
  },
  {
    path: "/prompt",
    name: "prompt",
    component: () => import("@/views/prompt/Main.vue"),
    meta: {
      action: `read`,
      resource: `prompt`,
    },
  },
  {
    path: "/workorder",
    name: "work-order",
    component: () => import("@/views/workorder/List.vue"),
    meta: {
      action: `read`,
      resource: `workorder`,
    },
  },
  {
    path: "/workorder/detail",
    name: "work-order-detail",
    component: () => import("@/views/workorder/Detail.vue"),
    meta: {
      action: `read`,
      resource: `workorder`,
    },
  },
  {
    path: "/bait",
    name: "bait-list",
    component: () => import("@/views/bait/List.vue"),
    meta: {
      action: `read`,
      resource: `bait`,
    },
  },
  {
    path: "/auth/login",
    name: "auth-login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      layout: "full",
      requiresAuth: false,
    },
  },
];
