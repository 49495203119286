import Vue from "vue";
import "@/libs";

import router from "./router";
import store from "./store";
import App from "./App.vue";
import { loadConfig } from "@/utils/configLoader";

import "@/mixins";
import "@/filters";

// Global Components
import "./global-components";

import i18n from "@/libs/i18n";

require("@core/scss/core.scss");
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

//import "@/utils/registerServiceWorker";

loadConfig().then(config => {
  if (config) {
    Vue.prototype.$config = config;

    new Vue({
      router,
      store,
      i18n,
      render: h => h(App),
    }).$mount("#app");
  } else {
    // Handle case where config couldn't be loaded
    console.error("Failed to load configuration, application cannot start");
  }
});
