import Vue from "vue";
import { Ability } from "@casl/ability";
import DefaultAbility from "./default";
import AdminAbility from "./admin";
import OwnerAbility from "./owner";
import EditorAbility from "./editor";

Vue.prototype.$ability = new Ability([]);

function getAbility(roles) {
  let ability = [...DefaultAbility];
  if (!roles) return ability;
  if (roles.includes("sa")) ability = [...ability, ...AdminAbility];
  if (roles.includes("o")) ability = [...ability, ...OwnerAbility];
  if (roles.includes("e")) ability = [...ability, ...EditorAbility];
  return ability;
}

function updateAbility(user) {
  let roles = null;
  if (user) roles = [user.roleCode, ...user.agentUser.map(x => x.agentRoleCode)];
  const abilities = getAbility(roles);
  Vue.prototype.$ability.update(abilities);
}

function removeAbility() {
  Vue.prototype.$ability.update([]);
}

export default Vue.prototype.$ability;

export { getAbility, updateAbility, removeAbility };
