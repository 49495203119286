<template>
  <b-button type="submit" variant="success" @click.stop="click">
    <feather-icon icon="SaveIcon"></feather-icon>
    {{ $t("general.save") }}
  </b-button>
</template>

<script>
export default {
  name: `SubmitButton`,
  methods: {
    click() {
      this.$emit(`onClick`);
    },
  },
};
</script>
