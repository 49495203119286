<template>
  <b-form @submit.prevent="save">
    <b-form-group :label="$t(`component.password.old`)">
      <b-form-input v-model="form.oldPassword" type="password" placeholder="**************" class="mb-2"></b-form-input>
    </b-form-group>

    <b-form-group :label="$t(`component.password.new`)">
      <b-form-input v-model="form.newPassword" type="password" placeholder="**************" class="mb-2"></b-form-input>
    </b-form-group>

    <b-form-group :label="$t(`component.password.confirm`)">
      <b-form-input v-model="form.confirmPassword" type="password" placeholder="**************"
        class="mb-2"></b-form-input>
    </b-form-group>

    <hr />

    <div class="d-flex">
      <b-button class="ml-auto" variant="success" @click.stop="save">Change Password</b-button>
    </div>
  </b-form>
</template>

<script>
import api from "@api";
export default {
  name: `ChangePassword`,
  data() {
    return {
      form: {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
    };
  },
  computed: {},
  methods: {
    async save() {
      try {
        if (this.form.newPassword === this.form.confirmPassword) {
          await api.put("useraccount/change-password", this.form);

          this.$emit("onSaveSuccess");

          this.showToast(`success`, this.$t(`component.password.addSuccess`));
          this.clearObject(this.form);
        } else {
          this.showToast(`error`, this.$t(`component.password.passwordNotMatch`));
        }
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>
