import Vue from "vue";
import axios from "axios";
import { GET_TOKEN, SET_TOKEN, SET_REFRESH_TOKEN } from "./service.localStorage";
import store from "@store";
import router from "@/router";

class ApiBaseClass {
  constructor(serviceKey) {
    this.serviceKey = serviceKey;
    this.http = null;
  }

  ensureInitialized() {
    if (!this.http) {
      const config = Vue.prototype.$config;
      const baseURL = config.baseUrl;

      if (!baseURL) {
        throw new Error(`Base URL for ${this.serviceKey} is not defined in configuration.`);
      }

      this.http = axios.create({
        baseURL: baseURL,
        headers: {
          "Content-Type": "application/json",
        },
      });

      this.setupInterceptors();
    }
  }

  setupInterceptors() {
    this.http.interceptors.request.use(
      config => this.request(config),
      error => this.errorRequest(error),
    );

    this.http.interceptors.response.use(
      response => this.successResponse(response),
      error => this.errorResponse(error),
    );
  }

  //INTERCEPTORS
  request(config) {
    const noAuthUrl = config.url.includes("token") || config.url.includes(`refreshtoken`);
    if (noAuthUrl) return config;
    const token = GET_TOKEN();
    if (token) config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  }

  errorRequest(error) {
    return Promise.reject(error);
  }

  successResponse(response) {
    if (response.config.responseType === `blob`) Promise.resolve(response);
    if (response.status == 200) Promise.resolve(response.data);
    return response.data;
  }

  errorResponse(error) {
    if (!error.response) {
      return Promise.reject("Network Error.");
    }

    // GENERAL ERROR
    if (error.response.status !== 401) {
      return Promise.reject(error.response.data);
    }

    if (error.config.url === "oauth/token") {
      return Promise.reject(error.response.data);
    }

    // REFRESH TOKEN FAIL
    if (error.config.url === "oauth/refreshtoken") {
      return Promise.reject("Refresh token expired. Please sign in.");
    }

    // TRY REFRESH TOKEN
    store.dispatch("auth/REFRESH_TOKEN").then(() => {
      return Promise.resolve();
    });
  }
  //INTERCEPTORS

  //METHODS

  async get(url, query) {
    this.ensureInitialized();
    if (query) url += this.buildQuery(query);
    return this.http.get(url);
  }

  async post(url, params, query) {
    this.ensureInitialized();
    if (query) url += this.buildQuery(query);
    return this.http.post(url, params);
  }

  async put(url, params, query) {
    this.ensureInitialized();
    if (query) url += this.buildQuery(query);
    return this.http.put(url, params);
  }

  async delete(url, query) {
    this.ensureInitialized();
    if (query) url += this.buildQuery(query);
    return this.http.delete(url);
  }

  async patch(url, data, query) {
    this.ensureInitialized();
    if (query) url += this.buildQuery(query);
    return this.http.patch(url, data);
  }

  async download(url) {
    this.ensureInitialized();
    return this.http.get(url, { responseType: "blob" });
  }

  async downloadPost(url, payload) {
    this.ensureInitialized();
    return this.http.post(url, payload, { responseType: "blob" });
  }

  async upload(url, formData) {
    this.ensureInitialized();
    var config = { headers: { "Content-Type": "multipart/form-data" } };
    return this.http.post(url, formData, config);
  }

  buildQuery(qObject) {
    let retVal = "";
    let keys = Object.keys(qObject);
    keys.forEach((x, i) => {
      if (i === 0) retVal += `?${x}=${qObject[x]}`;
      else retVal += `&${x}=${qObject[x]}`;
    });
    return retVal;
  }
}

export default ApiBaseClass;
